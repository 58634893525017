<template>
  <div class="box-card">
    <el-form :model="formdata" ref="formdata">
      <el-form-item label="停车时长阈值：" prop="hour" key="1" :rules="[{ required: true, message: '请输入小时', trigger: 'blur' }]">
        <div class="formondiv">
          <span> 车辆离场后，在</span>
          <el-input maxlength="4" v-model="formdata.hour" placeholder="请输入小时"
            oninput="value=value.replace(/\D/g, '').replace(/^0{1,}/g, '')"></el-input>
          <span> 小时内，未缴纳停车费，系统自动列入异常订单。 </span>
        </div>
      </el-form-item>
      <el-form-item label="停车费用阈值：" prop="amount" key="2"
        :rules="[{ required: true, message: '请输入金额', trigger: 'blur' }]">
        <div class="formondiv">
          <span> 待支付订单金额超过</span>
          <el-input maxlength="8" v-model="formdata.amount" placeholder="请输入金额"
            oninput="value = value.replace(/[^\d.]/g,'')"></el-input>
          <span> 元，系统自动列入异常订单。 </span>
        </div>
      </el-form-item>
      <el-form-item label="ETC追缴规则：" prop="etc_hour" key="3"
        :rules="[{ required: true, message: '请输入小时', trigger: 'blur' }]">
        <div class="formondiv">
          <span> 已开通ETC支付的车主，当超过</span>
          <el-input maxlength="8" v-model="formdata.etc_hour" placeholder="请输入小时"
            oninput="value = value.replace(/[^\d.]/g,'')"></el-input>
          <span> 小时依然未支付停车费的用户，启用ETC追缴流程，强制扣款。 </span>
        </div>
      </el-form-item>
    </el-form>
    <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="saveit" v-preventReClick>保 存</lbButton>
  </div>
</template>

<script>
import request from '@/api/orderApi'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      formdata: {
        hour: '',
        amount: '',
        etc_hour: ''
      }
    }
  },
  computed: {
    ...mapState('menuList', ['isRoutW']),
    queryForm () {
      var obj = {}
      obj.hour = parseInt(this.formdata.hour)
      obj.amount = this.formdata.amount * 1.0
      obj.etc_hour = parseInt(this.formdata.etc_hour)
      return obj
    }
  },
  mounted () {
    this.requestInit()
  },
  methods: {
    // goback () {
    //   this.$router.go(-1)
    // },
    saveit () {
      const that = this
      this.$refs.formdata.validate((valid) => {
        if (valid) {
          that.requestConfig()
        } else {
          return false
        }
      })
    },
    async requestInit () {
      const res = await request.abnormalOrderDetails()
      // console.log('异常订单----', res);
      if (res && res.Code === 200) {
        // console.log(res.Data);
        if (res.Data) {
          this.formdata = {
            hour: res.Data.hour,
            amount: res.Data.amount,
            etc_hour: res.Data.etc_hour
          }
        } else {
          this.formdata = {
            hour: '',
            amount: '',
            etc_hour: ''
          }
        }
      }
    },
    async requestConfig () {
      const res = await request.abnormalOrderConfig(this.queryForm)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
        // this.goback()
        this.$emit('closeDialog', false)
      }
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }
}
</script>

<style scoped lang="less">
.right {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}

.formondiv {
  text-align: start;
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.el-input {
  width: 120px;
  margin-left: 5px;
  margin-right: 5px;
}

.el-input__inner {
  text-align: center;
}

.box-card {
  position: relative;
  padding-bottom: 30px;

  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
  }
}</style>
