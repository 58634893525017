<template>
  <!-- 订单管理 —— 订单信息 -->
  <div class="box-card">
    <div style="text-align: left">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="停车场：">
          <el-select popper-class="my-select" clearable v-model="queryForm.parking_id" filterable default-first-option
            placeholder="请选择停车场" @change="onSubmit">
            <el-option v-for="item in parkingList" :key="item.parking_id" :label="item.parking_name"
              :value="item.parking_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车牌号：">
          <el-input maxlength="8" placeholder="请输入车牌号" :value="queryForm.plate_number"
            @input="(e) => (queryForm.plate_number = validPlatetoUpperCase(e))" @keyup.enter.native="onSubmit"></el-input>
        </el-form-item>
        <el-form-item label="异常订单：">
          <el-select popper-class="my-select" clearable v-model="queryForm.abnormal_source" placeholder="请选择异常订单来源"
            @clear="queryForm.abnormal_source = null" @change="onSubmit">
            <el-option v-for="item in getOptions('AbnormaSlource')" :key="item.value" :label="item.name"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="处理状态：">
          <el-select popper-class="my-select" clearable v-model="queryForm.status" placeholder="请选择处理状态"
            @clear="queryForm.status = null" @change="onSubmit">
            <el-option v-for="item in getOptions('Status')" :key="item.value" :label="item.name"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="异常订单">
        <template slot="heard-right">
          <div class="right">
            <lbButton type="setting" icon="peizhi2" @click="configuration"
              v-if="isShowBtn('3e4b6be89df349499f5aa529') || isShowBtn('92e3322361a74bf2b41ec558')">配置规则</lbButton>
          </div>
        </template>
        <div>
          <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange"
            @current-change="currentChange">
            <template slot-scope="{row}" slot="parking_time_length">
              {{ getTimeLength(row.parking_time_length) }}
            </template>
            <template slot-scope="{row}" slot="calculate_cost">
              {{ '￥' + row.calculate_cost.toFixed(2) }}
            </template>
            <template slot-scope="{row}" slot="order_source">
              <span>{{
                getWayName("OrderSource", row.order_source)
              }}</span>
            </template>
            <template slot-scope="{row}" slot="status">
              <span>{{ getWayName("Status", row.status) }}</span>
            </template>
            <template slot-scope="{row}" slot="create_time">
              <span>{{ validDateTime(row.create_time) }}</span>
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="err" icon="daichuli" hint="处理" @click="handleOrder(row)"
                v-if="row.status === 1 && (isShowBtn('3e4b6be8003400461f5da519') || isShowBtn('f1eaba247dae48f2b66cbc42'))">
              </lbButton>
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookit(row)" v-if="row.status === 2">
              </lbButton>
            </template>
          </avue-crud> -->
          <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
            tooltip-effect="dark" border stripe>
            <el-table-column prop="order_no" label="订单号" width="150"></el-table-column>
            <el-table-column prop="plate_number" label="车牌号"></el-table-column>
            <el-table-column prop="parking_name" label="停车场"></el-table-column>
            <el-table-column prop="parking_time_length" label="停车时长">
              <template slot-scope="scope">
                {{ getTimeLength(scope.row.parking_time_length) }}
              </template>
            </el-table-column>
            <el-table-column label="停车费用">
              <template slot-scope="scope">
                {{ '￥' + scope.row.calculate_cost.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column label="异常订单">
              <template slot-scope="scope">
                <span>{{
                  getWayName("AbnormaSlource", scope.row.abnormal_source)
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="处理状态">
              <template slot-scope="scope">
                <span>{{ getWayName("Status", scope.row.status) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" width="160">
              <template slot-scope="scope">
                <span>{{ validDateTime(scope.row.create_time) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="90">
              <template slot-scope="scope">
                <lbButton type="err" icon="daichuli" hint="处理" @click="handleOrder(scope.row)"
                  v-if="scope.row.status === 1 && (isShowBtn('3e4b6be8003400461f5da519') || isShowBtn('f1eaba247dae48f2b66cbc42'))">
                </lbButton>
                <lbButton type="succes" icon="chakan" hint="查看" @click="lookit(scope.row)" v-if="scope.row.status === 2">
                </lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="TotalCount"></el-pagination>
        </div>
      </le-card>
    </div>
    <lebo-dialog append-to-body title="异常订单审核" :isShow="dialogabnormalhandle" width="40%"
      @close="dialogabnormalhandle = false" footerSlot>
      <el-form :model="abnormalFrom" ref="abnormalFrom" :rules="abnormalfromRules">
        <el-form-item label="停车费用：" :label-width="formLabelWidth">
          <span>￥{{ itemObj.calculate_cost ? itemObj.calculate_cost.toFixed(2) : '0.00' }}</span>
          <!-- <el-row>
            <el-col :span="2">
              <span>￥{{ itemObj.calculate_cost }}</span>
            </el-col>
            <el-col :span="22"></el-col>
          </el-row> -->
        </el-form-item>
        <el-form-item label="费用调整为：" :label-width="formLabelWidth" prop="final_cost">
          <div style="display: flex;align-items: center;justify-content: flex-start;width: 50%;">
            <el-input maxlength="10" v-model.trim="abnormalFrom.final_cost" placeholder="请输入金额"
              @input="(e) => (queryForm.renew_amount = validNumber(e))"></el-input>
            <span style="margin-left:15px;">元</span>
          </div>
          <!-- <el-row>
            <el-col :span="14">
              <el-col :span="22">
                <el-input maxlength="10" v-model.trim="abnormalFrom.final_cost" placeholder="请输入金额" @input="(e) => (queryForm.renew_amount = validNumber(e))"></el-input>
              </el-col>
              <el-col :span="2"><span>元</span></el-col>
            </el-col>
            <el-col :span="10"></el-col>
          </el-row> -->
        </el-form-item>
        <el-form-item label="处理意见：" :label-width="formLabelWidth" prop="deal_with_msg">
          <el-input type="textarea" maxlength="100" class="handleinput" rows="5" :value="abnormalFrom.deal_with_msg"
            @input="(e) => (abnormalFrom.deal_with_msg = validSpecialChar(e))" placeholder="请详细描述订单详情"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="ignorAbnor()">忽略异常</el-button> -->
        <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="dialogabnormalhandle = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="handleit()">保 存</lbButton>
      </div>
    </lebo-dialog>
    <lebo-dialog append-to-body title="详情" :isShow="dialogdetail" width="40%" @close="dialogdetail = false" footerSlot
      closeOnClickModal>
      <el-form label-width="120">
        <el-form-item label="停车费用：" :label-width="formLabelWidth">
          <el-row>
            <el-col :span="2">
              <span>￥{{ itemObj.final_cost ? itemObj.final_cost.toFixed(2) : '0.00' }}</span>
            </el-col>
            <el-col :span="22"></el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="处理意见：" :label-width="formLabelWidth">
          <div style="width: 100%; text-align: start">
            <span>{{ look_deal_with_msg }}</span>
          </div>
        </el-form-item>
      </el-form>
    </lebo-dialog>
    <lebo-dialog append-to-body title="详情" :isShow="dialoglook" width="40%" @close="dialoglook = false" footerSlot
      closeOnClickModal>
      <el-form>
        <el-form-item label="原停车费用：" :label-width="formLabelWidth" class="dialoglookclass">
          <div style="width: 100%; text-align: start">
            <span>￥{{ itemObj.calculate_cost ? itemObj.calculate_cost.toFixed(2) : '0.00' }}</span>
          </div>
        </el-form-item>
        <el-form-item label="现停车费用：" :label-width="formLabelWidth" class="dialoglookclass">
          <div style="width: 100%; text-align: start">
            <span>￥{{ itemObj.final_cost ? itemObj.final_cost.toFixed(2) : '0.00' }}</span>
          </div>
        </el-form-item>
        <el-form-item label="处理意见：" :label-width="formLabelWidth" class="dialoglookclass">
          <div style="width: 100%; text-align: start">
            <span>{{ itemObj.deal_with_msg }}</span>
          </div>
        </el-form-item>
        <el-form-item label="处理人：" :label-width="formLabelWidth" class="dialoglookclass">
          <div style="width: 100%; text-align: start">
            <span>{{ itemObj.deal_with_str }}</span>
          </div>
        </el-form-item>
      </el-form>
    </lebo-dialog>
    <!-- 编辑配置规则 -->
    <lebo-dialog :isShow="showConfig" width="70%" title="配置规则" :footerSlot="true" @close="showConfig = false">
      <abnormalorderconfig :id="dialogOrderId" @closeDialog="closeConfig" v-if="showConfig"></abnormalorderconfig>
    </lebo-dialog>
  </div>
</template>

<script>
import request from '@/api/orderApi'
import { mapState, mapGetters } from 'vuex'
import abnormalorderconfig from './components/abnormalorderConfig.vue/index.vue'

export default {
  components: { abnormalorderconfig },
  data () {
    return {
      // 查询表单
      queryForm: {
        parking_id: null,
        plate_number: '',
        order_source: null,
        status: null,
        PageSize: 10,
        PageIndex: 1,
        abnormal_source: null
      },
      // 表格数据
      tableData: [],
      formLabelWidth: '120px',
      dialogabnormalhandle: false,
      abnormalFrom: {
        deal_with_msg: '',
        final_cost: ''
      },
      abnormalfromRules: {
        deal_with_msg: [
          { required: true, message: '请输入处理意见', trigger: 'blur' }
        ],
        final_cost: [
          { required: true, message: '请输入金额', trigger: 'blur' },
          { pattern: /^([1-9]\d*|0)(\.\d{1,2})?$/, message: '请输入正确的金额', trigger: 'blur' }
        ]
      },
      dialogdetail: false,
      dialoglook: false,
      look_deal_with_msg: '',
      itemObj: {
        calculate_cost: '',
        final_cost: '',
        deal_with_msg: '',
        deal_with_str: ''
      },
      abnormal_id: '',
      TotalCount: 0,
      showConfig: false, // 配置规则弹框
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '订单号',
            prop: 'order_no',
            width: 150
          }, {
            label: '车牌号',
            prop: 'plate_number'
          }, {
            label: '停车场',
            prop: 'parking_name'
          }, {
            label: '停车时长',
            prop: 'parking_time_length',
            slot: true
          }, {
            label: '停车费用',
            prop: 'calculate_cost',
            slot: true
          }, {
            label: '订单来源',
            prop: 'order_source',
            slot: true
          }, {
            label: '处理状态',
            prop: 'status',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () { },
  computed: {
    ...mapState(['parkingList']),
    ...mapState('menuList', ['isRoutW']),
    ...mapGetters(['getWayName', 'getOptions'])
  },
  watch: {
    dialogabnormalhandle (newValue, oldValue) {
      if (!newValue) {
        this.$refs.abnormalFrom.resetFields()
      }
    }
  },
  mounted () {
    this.requestAbnorOrderList()
  },
  methods: {
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.requestAbnorOrderList()
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.requestAbnorOrderList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.requestAbnorOrderList()
    // },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.requestAbnorOrderList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.requestAbnorOrderList()
    // },
    lookit (row) {
      this.itemObj.calculate_cost = row.calculate_cost
        ? row.calculate_cost
        : ''
      this.itemObj.final_cost = row.final_cost ? row.final_cost : ''
      this.itemObj.deal_with_msg = row.deal_with_msg ? row.deal_with_msg : ''
      this.itemObj.deal_with_str = row.deal_with_str ? row.deal_with_str : ''
      this.dialoglook = true
    },
    handleit () {
      var that = this
      this.$refs.abnormalFrom.validate((valid) => {
        if (valid) {
          that.look_deal_with_msg = that.abnormalFrom.deal_with_msg
          that.requsetAbnormalAudit()
        } else {
          return false
        }
      })
    },
    handleOrder (row) {
      this.abnormal_id = row._id
      this.itemObj.calculate_cost = row.calculate_cost
        ? row.calculate_cost
        : ''
      this.dialogabnormalhandle = true
      console.log('roereo', row)
    },
    configuration () {
      this.showConfig = true
      // this.$router.push('/abnormal-order-config')
    },
    async requestAbnorOrderList () {
      var that = this
      const params = {
        parking_id: that.queryForm.parking_id,
        plate_number: that.queryForm.plate_number,
        // order_source: that.queryForm.order_source,
        status: that.queryForm.status,
        PageSize: that.queryForm.PageSize,
        PageIndex: that.queryForm.PageIndex,
        abnormal_source: that.queryForm.abnormal_source
      }
      // if (this.queryForm.order_source === '' || this.queryForm.order_source === null) {
      //   params.order_source = 0
      // }
      if (this.queryForm.status === '' || this.queryForm.status === null) {
        params.status = 0
      }
      if (this.queryForm.abnormal_source === '' || this.queryForm.abnormal_source === null) {
        params.abnormal_source = 0
      }
      try {
        const res = await request.abnormalOrderList(params)
        if (res && res.Code === 200) {
          this.tableData = res.Data.DataList
          this.TotalCount = res.Data.TotalCount
        }
        this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      } catch (error) { }
    },
    async requsetAbnormalAudit () {
      const res = await request.abnormalAudit({
        deal_with_msg: this.abnormalFrom.deal_with_msg,
        final_cost: this.abnormalFrom.final_cost * 1.0,
        _id: this.abnormal_id
      })
      if (res && res.Code === 200) {
        this.dialogabnormalhandle = false
        this.$refs.abnormalFrom.resetFields()
        // this.dialogdetail = true
        this.requestAbnorOrderList()
        this.showDetailAfterChange(this.abnormal_id)
      }
    },
    async showDetailAfterChange (id) {
      const res = await request.abnormalDetial({ abnormal_Id: id })
      if (res && res.Code === 200) {
        const obj = {
          calculate_cost: res.Data.calculate_cost,
          final_cost: res.Data.final_cost,
          deal_with_msg: res.Data.deal_with_msg,
          deal_with_str: res.Data.deal_with_str
        }
        this.lookit(obj)
      }
    },
    ignorAbnor () {
      this.dialogabnormalhandle = false
    },
    // 关闭配置规则
    closeConfig () {
      this.showConfig = false
      this.requestAbnorOrderList()
    }
  }
}
</script>

<style scoped  lang="less">
.box-card {
  margin-top: 20px;
}

.queryForm {
  /deep/ .el-form-item__content {
    /deep/ .el-input {
      /deep/ input {
        width: 200px !important;
      }
    }
  }
}

.handleinput>input {
  text-align: start;
  vertical-align: start;
  height: 150px;
  line-height: 30px;
}

// .el-input__inner {
//   height: 36px;
//   line-height: 36px;
// }
.button_box {
  display: flex;
}

.button_box .el-button {
  margin-left: 15px;
  width: auto;
}

.el-dialog__body {
  padding: 20px;
}

.el-button {
  width: auto !important;
}

.dialoglookclass {
  margin-bottom: 0 !important;
}
</style>
